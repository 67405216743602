import { RouteNode } from '../../@types';
import { CoinHand } from '../../components/atom/icon';
import { claimType } from './configuration/claimType';
import { claim } from './setup/claim';
import { claimRequest } from './claimRequest/claimRequest';
import { claimProcess } from './claimProcess/claimProcess';

export const claimRoute: RouteNode[] = [
  {
    label: 'Claim',
    icon: CoinHand,
    path: 'claim',
    component: null,
    child: [
      {
        label: 'Configuration',
        path: 'configuration',
        component: null,
        child: [...claimType],
      },
      {
        label: 'Setup',
        path: 'setup',
        component: null,
        child: [...claim],
      },
      ...claimRequest,
      ...claimProcess,
    ],
  },
];
