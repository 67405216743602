import { CompensationRoute } from './Compensation';
import { AccountingRoute } from './accounting';
import { BenefitRoute } from './benefits';
import { Dashboard } from './dashboard';
import { EmployeeRoute } from './employee/index';
import { MasterDataRoute } from './masterData';
// import { NewsRoute } from './news';
import { Organization } from './organization';
import { Payroll } from './payroll';
import { ReportRoute } from './reports';
import { TimeRoute } from './time';
import { importFileRoute } from './import';
import { exportFileRoute } from './export';
import { claimRoute } from './claim';

export default [
  ...Dashboard,
  ...EmployeeRoute,
  ...Payroll,
  ...claimRoute,
  ...TimeRoute,
  // ...BenefitRoute,
  ...Organization,
  ...MasterDataRoute,
  ...AccountingRoute,
  ...ReportRoute,
  ...CompensationRoute,
  ...importFileRoute,
  ...exportFileRoute,
];
