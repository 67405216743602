import { lazy } from 'react';

const List = lazy(() => import('../../../pages/claimProcess/List'));
const Detail = lazy(() => import('../../../pages/claimProcess/Detail'));
const Create = lazy(() => import('../../../pages/claimProcess/Create'));

export const claimProcess = [
  {
    label: 'Claim Process',
    child: null,
    path: 'claim-process',
    component: List,
  },
  {
    label: '',
    child: null,
    path: 'claim-process/create',
    component: Create,
  },
  {
    label: '',
    child: null,
    path: 'claim-process/detail/:id',
    component: Detail,
  },
];
