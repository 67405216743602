import { Grid, Skeleton, Typography, useTheme } from '@mui/material';
import { ReactNode } from 'react';

interface LabelTextModalProps {
  label: string;
  texts?: Array<string>;
  textComponents?: Array<ReactNode>;
  isLoading?: boolean;
}
const LabelTextModal = ({
  label,
  texts = [],
  textComponents = [],
  isLoading,
}: LabelTextModalProps) => {
  const { palette } = useTheme();
  return (
    <Grid item container gap="6px" direction="column">
      <Grid item>
        <Typography variant="body14" fontWeight={400} color={palette.gray[80]}>
          {label}
        </Typography>
      </Grid>
      <Grid item>
        {isLoading && (
          <Skeleton variant="text" sx={{ fontSize: '1.5rem', width: '100%' }} />
        )}
        {!isLoading &&
          texts.map(text => (
            <Typography
              key={text}
              variant="body14"
              fontWeight={400}
              display="block"
              color={palette.gray[100]}
            >
              {text}
            </Typography>
          ))}
        {!isLoading &&
          textComponents.map((component, index) => (
            <div key={`component-${index + 1}`}>{component}</div>
          ))}
      </Grid>
    </Grid>
  );
};

export default LabelTextModal;
