import { AttendanceProcessList } from '../../../../@types/AttendanceProcessTypes';
import { QueryParams } from '../../../../@types/QueryParams';
import {
  ClaimProcessDetailDailyList,
  ClaimProcessDetailSummaryList,
} from '../../../../@types/claimProcessTypes';
import { apiSlice } from '../../api/apiSlice';

export const claimProcessApiSlice = apiSlice.injectEndpoints({
  endpoints: builder => ({
    claimProcessList: builder.query<AttendanceProcessList, QueryParams>({
      query: params => {
        const { search, page, sortType, sortBy, searchString } = params;
        return {
          url: '/claims/claim-processes',
          params: { search, page, sortType, sortBy, ...searchString },
        };
      },
      providesTags: ['claimProcessList'],
    }),
    createClaimProcess: builder.mutation<void, any>({
      query: payload => ({
        url: '/claims/claim-processes',
        method: 'POST',
        body: payload,
      }),
      invalidatesTags: ['claimProcessList'],
    }),
    deleteClaimProcess: builder.mutation<void, { processID: string }>({
      query: params => {
        const { processID } = params;
        return {
          url: `/claims/claim-processes/${processID}`,
          method: 'DELETE',
        };
      },
      invalidatesTags: ['claimProcessList'],
    }),
    // STEP 2 CREATE
    claimProcessDynamicHeader: builder.query<any, any>({
      query: ({ cutOffFrom, cutOffTo, referenceId, source, data }) => ({
        url: '/claims/claim-processes/dynamic-header',
        params: {
          cutOffFrom,
          cutOffTo,
          referenceId,
          source,
          data,
        },
      }),
    }),
    claimProcessReviewSummaryList: builder.query<
      any,
      QueryParams & {
        cutOffFrom: number;
        cutOffTo: number;
        referenceId: string;
        source?: string;
        data?: string;
      }
    >({
      query: params => {
        const {
          search,
          page,
          sortType,
          sortBy,
          searchString,
          cutOffFrom,
          cutOffTo,
          referenceId,
          source,
          data,
        } = params;
        return {
          url: '/claims/claim-processes/summary-review',
          params: {
            search,
            page,
            sortType,
            sortBy,
            cutOffFrom,
            cutOffTo,
            referenceId,
            source,
            data,
            ...searchString,
          },
        };
      },
    }),
    claimProcessReviewDailyList: builder.query<
      any,
      QueryParams & {
        cutOffFrom: number;
        cutOffTo: number;
        referenceId: string;
        source?: string;
        data?: string;
      }
    >({
      query: params => {
        const {
          search,
          page,
          sortType,
          sortBy,
          searchString,
          cutOffFrom,
          cutOffTo,
          referenceId,
          source,
          data,
        } = params;
        return {
          url: '/claims/claim-processes/daily-review',
          params: {
            search,
            page,
            sortType,
            sortBy,
            cutOffFrom,
            cutOffTo,
            referenceId,
            source,
            data,
            ...searchString,
          },
        };
      },
    }),

    // CLAIM PROCESS DETAIL
    claimProcessDetailSummaryList: builder.query<
      ClaimProcessDetailSummaryList,
      QueryParams & { processID: string }
    >({
      query: params => {
        const {
          processID,
          page,
          limit,
          sortBy,
          sortType,
          searchString,
          search,
        } = params;
        return {
          url: `/claims/claim-processes/${processID}/summary`,
          params: {
            page,
            limit,
            sortBy,
            sortType,
            search,
            ...searchString,
          },
        };
      },
      providesTags: ['claimProcessDetailSummaryList'],
    }),
    claimProcessDetailDailyList: builder.query<
      ClaimProcessDetailDailyList,
      QueryParams & { processID: string }
    >({
      query: params => {
        const {
          processID,
          page,
          limit,
          sortBy,
          sortType,
          searchString,
          search,
        } = params;
        return {
          url: `/claims/claim-processes/${processID}/daily`,
          params: {
            page,
            limit,
            sortBy,
            sortType,
            search,
            ...searchString,
          },
        };
      },
      providesTags: ['claimProcessDetailDailyList'],
    }),
    claimProcessDetailHeader: builder.query<any, { processID: string }>({
      query: params => {
        const { processID } = params;
        return {
          url: `/claims/claim-processes/${processID}/header`,
        };
      },
    }),
    claimReprocessingStatus: builder.query<
      {
        statusCode: null | 'REPROCESSING' | 'COMPLETED';
        startReprocessingTime: number;
      },
      { processID: string }
    >({
      query: params => {
        const { processID } = params;
        return {
          url: `/claims/claim-processes/status/${processID}`,
        };
      },
    }),
    // REPROCESS
    reprocessClaim: builder.mutation<
      any,
      {
        processID: string;
        startDate: number;
        endDate: number;
        startReprocessingTime: number;
      }
    >({
      query: params => {
        const { processID, startDate, endDate, startReprocessingTime } = params;
        return {
          url: `claims/claim-processes/reprocess/${processID}`,
          method: 'POST',
          body: {
            startDate,
            endDate,
            startReprocessingTime,
          },
        };
      },
      invalidatesTags: [
        'claimProcessDetailDailyList',
        'claimProcessDetailSummaryList',
      ],
    }),
  }),
});

export const {
  useClaimProcessListQuery,
  useCreateClaimProcessMutation,
  useDeleteClaimProcessMutation,
  useClaimProcessDetailDailyListQuery,
  useClaimProcessDetailSummaryListQuery,
  useClaimProcessDetailHeaderQuery,
  useReprocessClaimMutation,
  useClaimReprocessingStatusQuery,
  useClaimProcessDynamicHeaderQuery,
  useClaimProcessReviewDailyListQuery,
  useClaimProcessReviewSummaryListQuery,
} = claimProcessApiSlice;
