import { createSlice, current } from '@reduxjs/toolkit';
import dayjs from '../../utils/dayjs';
import { employeeApiSlice } from './service/employeeApislice';
import { HistorySlice } from './service/report/historyApiSlice';

interface initialStateI {
  pages: { [key: string]: number };
  columnsDisplay: { [key: string]: string[] };
  filter: { [key: string]: any };
  search: any;
  searchLabel: any;
  searchString: any;
  selectedTab: { [key: string]: number };
  headerDate: { [key: string]: string };
  isLoading?: boolean;
  selectedYear: number;
}

const initialState: initialStateI = {
  pages: {},
  columnsDisplay: {},
  filter: {},
  search: {},
  searchLabel: {},
  searchString: {},
  selectedTab: {},
  headerDate: {},
  isLoading: false,
  selectedYear: new Date().getFullYear(),
};

const mappingToSearchLabel = (data: any) => {
  let result: any = [];
  Object.keys(data).forEach(key => {
    if (Array.isArray(data[key])) {
      const res = data[key].map((x: any) => ({
        label: x.label,
        value: x.value,
        key,
      }));
      result = [...result, ...res];
    } else {
      if (data[key]?.label) {
        result.push({
          label: data[key].label,
          value: data[key].value,
          key,
        });
        return;
      }
      if (data[key]) {
        result.push({
          label: data[key],
          value: key,
          key,
        });
      }
    }
  });
  return result;
};

export const mappingToSearchString = (data: any) => {
  let searchString = {};
  Object.keys(data).forEach(key => {
    if (Array.isArray(data[key])) {
      const mappingData = data[key].map((value: any) => value.value);
      if (mappingData.length > 0) {
        searchString = {
          ...searchString,
          [key]: mappingData.join(','),
        };
      }
    } else {
      if (data[key]?.label && data[key].value) {
        searchString = {
          ...searchString,
          [key]: data[key].value,
        };
      }
      if (!data[key]?.label && data[key]) {
        searchString = {
          ...searchString,
          [key]: data[key],
        };
      }
    }
  });
  return searchString;
};

const utilSlice = createSlice({
  name: 'utils',
  initialState,
  reducers: {
    setPages: (state, action) => {
      state.pages = {
        ...state.pages,
        ...action.payload,
      };
    },
    resetPages: (state, action) => {
      const { payload } = action;
      const pagesState = { ...state.pages };
      Object.keys(pagesState).forEach((k, _idx) => {
        if (!payload.includes(k)) {
          delete pagesState[k];
        }
      });
      state.pages = { ...pagesState };
    },
    resetSearch: (state, action) => {
      const { payload } = action;
      const searchState = { ...state.search };

      Object.keys(searchState).forEach((k, _idx) => {
        if (!payload.includes(k)) {
          delete searchState[k];
        }
      });
      state.search = { ...searchState };
    },
    resetFilter: (state, action) => {
      const { payload } = action;
      const filterState = { ...state.filter };
      const searchLabelState = { ...state.searchLabel };
      const searchStringState = { ...state.searchString };

      Object.keys(filterState).forEach((k, _idx) => {
        if (!payload.includes(k)) {
          delete filterState[k];
        }
      });
      Object.keys(searchLabelState).forEach((k, _idx) => {
        if (!payload.includes(k)) {
          delete searchLabelState[k];
        }
      });
      Object.keys(searchStringState).forEach((k, _idx) => {
        if (!payload.includes(k)) {
          delete searchStringState[k];
        }
      });
      state.filter = { ...filterState };
      state.searchLabel = { ...searchLabelState };
      state.searchString = { ...searchStringState };
    },
    setSearch: (state, action) => {
      const { payload } = action;
      const key = Object.keys(payload)?.[0];
      state.search = {
        ...state.search,
        [key]: payload[key],
      };
    },
    setColumnDisplay: (state, action) => {
      const { payload } = action;
      state.columnsDisplay = {
        ...state.columnsDisplay,
        ...payload,
      };
    },
    setSearchFilter: (state, action) => {
      const searchState = { ...state.search };
      const { payload } = action;
      state.search = '';
      state.filter = {
        ...state.filter,
        ...payload,
      };
      const data =
        Object.keys(payload).length > 0 && payload[Object.keys(payload)[0]];

      const result: any = mappingToSearchLabel(data);
      state.searchLabel = {
        ...state.searchLabel,
        [Object.keys(payload)[0]]: result,
      };

      const searchString = mappingToSearchString(data);
      state.searchString = {
        ...state.searchString,
        [Object.keys(payload)[0]]: searchString,
      };

      delete searchState[Object.keys(payload)[0]];
      state.search = searchState;
    },
    removeFilter: (state, action) => {
      const { payload } = action;
      const removedItem = payload[Object.keys(payload)[0]];
      const data = current(state).filter[Object.keys(payload)[0]];

      let dataRemoved = {};
      if (Array.isArray(data[removedItem.key])) {
        const newData = data[removedItem.key].filter(
          (item: any) =>
            item.label !== removedItem.label && item.value !== removedItem.value
        );
        dataRemoved = { ...data, [removedItem.key]: newData };
      } else {
        if (data[removedItem.key]?.label) {
          dataRemoved = { ...data, [removedItem.key]: undefined };
        }
        if (!data[removedItem.key]?.label) {
          dataRemoved = { ...data, [removedItem.key]: undefined };
        }
      }
      state.filter = {
        ...current(state).filter,
        [Object.keys(payload)[0]]: dataRemoved,
      };

      let result: any = [];
      result = mappingToSearchLabel(dataRemoved);
      state.searchLabel = {
        ...state.searchLabel,
        [Object.keys(payload)[0]]: result,
      };

      const searchString = mappingToSearchString(dataRemoved);
      state.searchString = {
        ...state.searchString,
        [Object.keys(payload)[0]]: searchString,
      };
    },
    resetAllFilter: (state, action) => {
      const { payload } = action;
      const filterState = { ...state.filter };
      const searchLabelState = { ...state.searchLabel };
      const searchStringState = { ...state.searchString };
      delete filterState[payload];
      delete searchLabelState[payload];
      delete searchStringState[payload];
      state.filter = filterState;
      state.searchLabel = searchLabelState;
      state.searchString = searchStringState;
    },
    setTab: (state, action) => {
      const { payload } = action;
      const key = Object.keys(payload)?.[0];
      state.selectedTab = {
        ...state.selectedTab,
        [key]: payload[key],
      };
    },
    setHeaderDate: (state, action) => {
      const { payload } = action;
      const key = Object.keys(payload)?.[0];
      state.headerDate = {
        ...state.headerDate,
        [key]: payload[key],
      };
    },
    resetHeaderDate: (state, _action) => {
      state.headerDate = {};
    },
    setSelectedYear: (state, action) => {
      const { payload } = action;
      state.selectedYear = payload;
    },
  },
  extraReducers(builder) {
    builder.addMatcher(
      employeeApiSlice.endpoints.cancelUpcomingEmployeeCostCenter
        .matchFulfilled,
      (state, _action) => {
        state.headerDate = {};
      }
    );
    builder.addMatcher(
      HistorySlice.endpoints.useDownloadHistory1721A1.matchPending,
      state => {
        state.isLoading = true;
      }
    );
    builder.addMatcher(
      HistorySlice.endpoints.useDownloadHistory1721A1.matchRejected,
      state => {
        state.isLoading = false;
      }
    );
    builder.addMatcher(
      HistorySlice.endpoints.useDownloadHistory1721A1.matchFulfilled,
      state => {
        state.isLoading = false;
      }
    );
    builder.addMatcher(
      HistorySlice.endpoints.downloadHistoryBupot2126.matchPending,
      state => {
        state.isLoading = true;
      }
    );
    builder.addMatcher(
      HistorySlice.endpoints.downloadHistoryBupot2126.matchRejected,
      state => {
        state.isLoading = false;
      }
    );
    builder.addMatcher(
      HistorySlice.endpoints.downloadHistoryBupot2126.matchFulfilled,
      state => {
        state.isLoading = false;
      }
    );
  },
});

export const {
  setPages,
  resetPages,
  setColumnDisplay,
  setSearchFilter,
  setSearch,
  removeFilter,
  resetAllFilter,
  resetFilter,
  resetSearch,
  setTab,
  setHeaderDate,
  resetHeaderDate,
  setSelectedYear,
} = utilSlice.actions;
export const utilsReducer = utilSlice.reducer;
