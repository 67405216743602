export const tableListHeaderKey = {
  timeOffRequestId: 'ID',
  employeeNumber: 'Employee ID',
  employeeFullName: 'Full Name',
  typeOffName: 'Type',
  durationName: 'Duration',
  totalDay: 'Total Day(s)',
  date: 'Date',
  statusName: 'Status',
};

export const detailHeaderKey: { [key: string]: any } = {
  timeOffRequestId: 'ID',
  employeeId: 'Employee ID',
  fullName: 'Full Name',
  type: 'Type',
  duration: 'Duration',
  dates: 'Date',
  totalDays: 'Total Day(s)',
  attachment: 'Attachment',
  remarks: 'Remarks',
};
