import React from 'react';
import { RouteNode } from '../../@types';

const ClaimRequestDetails = React.lazy(
  () => import('../../pages/employee/claim/ClaimRequestDetails')
);

const ClaimBalanceDetails = React.lazy(
  () => import('../../pages/employee/claim/ClaimBalanceDetails')
);

const ClaimLogHistory = React.lazy(
  () => import('../../pages/employee/claim/ClaimLogHistory')
);

const employeeDetailClaimRoute: RouteNode[] = [
  {
    label: '',
    path: 'employee/detail/:id/claim-request-details',
    component: ClaimRequestDetails,
    child: null,
  },
  {
    label: '',
    path: 'employee/detail/:id/claim-balance-details',
    component: ClaimBalanceDetails,
    child: null,
  },
  {
    label: '',
    path: 'employee/detail/:id/claim/balance/log-history/:balanceId',
    component: ClaimLogHistory,
    child: null,
  },
];

export default employeeDetailClaimRoute;
