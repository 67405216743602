import { Collapse, List } from '@mui/material';
import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { NavMenu } from '../../atom';
import { RouteProps, RouteNode } from '../../../@types/RouteProps';
import { useAppDispatch, useAppSelector } from '../../../store';
import { closeSideBar } from '../../../store/feature/responsiveSlice';

const TreeList: React.FC<RouteProps> = ({ data, sx }) => {
  const { dataAccess } = useAppSelector(state => state.userAccess);
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useAppDispatch();
  const [expanded, setExpanded] = useState<string[]>([]);

  const sidebarDictionary: { [key: string]: Array<string> } = {
    Home: ['home'],
    Employee: ['employee'],
    Payroll: [
      'run_payroll',
      'pay_group',
      'pay_item',
      'accumulator',
      'bank_file',
      'exchange_rate',
      'formula',
      'payslip_template',
      'statutory',
      'value_rounding',
    ],
    'Run Payroll': ['run_payroll'],
    'Setup payroll': ['pay_group', 'pay_item'],
    'Setup time': [
      'holiday',
      'job_site',
      'overtime',
      'shift',
      'schedule',
      'time_off',
    ],
    Claim: ['claim_type', 'claim'],
    'Configuration claim': ['claim_type'],
    'Setup claim': ['claim'],
    'Claim Type': ['claim_type'],
    'Claim Request': ['claim_request'],
    'Claim Process': ['claim_process'],
    'Pay Group': ['pay_group'],
    'Pay Item': ['pay_item'],
    Accumulator: ['accumulator'],
    'Bank File': ['bank_file'],
    'Exchange Rate': ['exchange_rate'],
    Formula: ['formula'],
    'Payslip Template': ['payslip_template'],
    Statutory: ['statutory'],
    'Value Rounding': ['value_rounding'],
    Time: [
      'analytics',
      'work_schedule',
      'attendance_process',
      'daily_attendance',
      'overtime_request',
      'time_off_request',
      'holiday',
      'job_site',
      'overtime',
      'shift',
      'schedule',
      'time_off',
      'attendance_machine',
      'attendance_status',
      'overtime_item',
      'policy',
      'time_event',
      'time_item',
      'time_off_item',
    ],
    Analytics: ['analytics'],
    'Work Schedule': ['work_schedule'],
    Attendance: ['attendance_process', 'daily_attendance'],
    'Attendance Process': ['attendance_process'],
    'Daily Attendance': ['daily_attendance'],
    Request: ['overtime_request'],
    Holiday: ['holiday'],
    'Job Site': ['job_site'],
    Shift: ['shift'],
    Schedule: ['schedule'],
    'Configuration payroll': [
      'accumulator',
      'bank_file',
      'exchange_rate',
      'formula',
      'payslip_template',
      'statutory',
      'value_rounding',
    ],
    'Configuration time': [
      'attendance_machine',
      'attendance_status',
      'overtime_item',
      'policy',
      'time_item',
      'time_off_item',
    ],
    'Attendance Machine': ['attendance_machine'],
    'Attendance Status': ['attendance_status'],
    'Overtime Item': ['overtime_item'],
    Policy: ['policy'],
    'Time Event': ['time_event'],
    'Time Item': ['time_item'],
    'Time Off Item': ['time_off_item'],
    Overtime: ['overtime_request'],
    'Time Off': ['time_off_request'],
    Organization: ['legal_entity', 'job_grade'],
    'Legal Entity': ['legal_entity'],
    'Organization Structure': ['organization_structure'],
    'Job Grade': ['job_grade'],
    'Job Level': ['job_level'],
    'Job Position': ['job_position'],
    'Organization Unit': ['organization_unit'],
    'Work Location': ['work_location'],
    File: ['file'],
    'Master Data': ['bank', 'cost_center', 'employment_type', 'tax_location'],
    Bank: ['bank'],
    'Cost Center': ['cost_center'],
    'Employment Type': ['employment_type'],
    'Tax Location': ['tax_location'],
    Accounting: ['chart_of_account'],
    'Chart of Accounts': ['chart_of_account'],
    Report: ['standard_report'],
    'Standard Report': ['standard_report'],
    Import: ['import'],
    Export: ['export'],
    'Custom Fields': ['custom_field'],
    'User Interface': ['user_interface'],
    Security: ['user_access', 'access_control', 'ess_access_control'],
    'User Access': ['user_access'],
    'Access Control': ['access_control'],
    'ESS Access Control': ['ess_access_control'],
  };

  const checkAccess = (label: string) => {
    let viewable = false;
    for (let index = 0; index < sidebarDictionary[label]?.length; index += 1) {
      const element = sidebarDictionary[label][index];
      if (dataAccess[element]?.indexOf('VIEW') > -1) {
        viewable = true;
        break;
      }
    }
    return viewable;
  };

  // Expand according to current URL on page load or location change
  useEffect(() => {
    const pathSegments = location.pathname
      .replace(/^\//, '') // remove leading slash
      .split('/')
      .filter(Boolean); // remove empty segments

    const expansionsToSet: string[] = [];
    for (let i = 0; i < pathSegments.length - 1; i += 1) {
      const segmentPath = pathSegments.slice(0, i + 1).join('/');
      expansionsToSet.push(segmentPath);
    }

    setExpanded(expansionsToSet);
  }, [location.pathname]);

  const handleClick = (fullPath: string, isParent: boolean, depth: number) => {
    if (!isParent) {
      // Leaf node: If it's top-level (depth=0), close other menus
      if (depth === 0) {
        // Close all expansions when navigating to a top-level leaf menu
        setExpanded([]);
      }
      navigate(fullPath === '/' ? '/' : `/${fullPath}`);
      dispatch(closeSideBar());
    } else {
      // Parent node: Just toggle its expansion, do not close other top-level menus
      // because user wants no top-level closing unless it's a leaf navigation.
      setExpanded(prev => {
        if (prev.includes(fullPath)) {
          return prev.filter(item => item !== fullPath);
        }
        return [...prev, fullPath];
      });
    }
  };

  const checkIsActive = (fullPath: string) =>
    location.pathname.startsWith(`/${fullPath}`);

  const formatLabel = (label: string, parentPaths: string[]) => {
    const keys = ['Setup', 'Configuration'];
    if (keys.includes(label) && parentPaths.includes('payroll')) {
      return `${label} payroll`;
    }
    if (keys.includes(label) && parentPaths.includes('time')) {
      return `${label} time`;
    }
    if (keys.includes(label) && parentPaths.includes('claim')) {
      return `${label} claim`;
    }
    return label;
  };

  const renderList = (
    nodes: RouteNode[],
    depth: number,
    parentPaths: string[]
  ) => (
    <List
      component="nav"
      sx={{
        padding: 0,
        display: 'flex',
        flexDirection: 'column',
        gap: '4px',
        ...sx,
      }}
    >
      {nodes
        .filter(node => node.label !== 'child')
        .map(node => {
          const { icon: ComponentIcon } = node;
          const fullPath = [...parentPaths, node.path]
            .filter(Boolean)
            .join('/');

          return (
            <React.Fragment key={fullPath}>
              {node.label &&
                checkAccess(formatLabel(node.label, parentPaths)) && (
                  <NavMenu
                    onClick={() =>
                      handleClick(fullPath, node.child !== null, depth)
                    }
                    path={fullPath}
                    label={node.label}
                    isActive={
                      node.path === '/' && location.pathname === '/'
                        ? true
                        : checkIsActive(fullPath)
                    }
                    depth={depth}
                    icon={ComponentIcon ? <ComponentIcon /> : null}
                    isParent={node.child !== null}
                    isExpended={expanded.includes(fullPath)}
                  />
                )}
              {node.child && (
                <Collapse
                  in={expanded.includes(fullPath)}
                  timeout="auto"
                  unmountOnExit
                >
                  {renderList(node.child, depth + 1, [
                    ...parentPaths,
                    node.path,
                  ])}
                </Collapse>
              )}
            </React.Fragment>
          );
        })}
    </List>
  );

  return renderList(data, 0, []);
};

export default TreeList;
