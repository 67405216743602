import { OptionProps } from '../../../@types';
import {
  CreateEmployeePayload,
  EducationProps,
  EmpBalanceLogHistoryHeaderTypes,
  EmpBalanceLogHistoryListTypes,
  EmployeeProfileDataType,
  EmployeeProps,
  EmployeeTimeOffEntitlementList,
  EmployeeTimeOffHistoryList,
  ExperienceProps,
  HistoryProps,
} from '../../../@types/EmployeeTypes';
import { ListResponse } from '../../../@types/ListResponseProps';
import { formatDateToPostUnix } from '../../../utils/date';
import { apiSlice } from '../api/apiSlice';
import { QueryParams as QueryParamsPagination } from '../../../@types/QueryParams';
import { AwaitingApprovalList } from '../../../@types/EmployeeTimeOff';
import { WrapperPaginationType } from '../../../@types/WrapperTypes';

interface QueryParams {
  id: string;
  groupId?: string;
  sort?: string;
  sortType?: string;
}

export const employeeApiSlice = apiSlice.injectEndpoints({
  endpoints: builder => ({
    getEmployeeList: builder.query<ListResponse<EmployeeProps>, any>({
      query: args => {
        const { search, page, sortType, sortBy, searchString } = args;
        const keyDateList: Array<string> = [
          'birthDateFrom',
          'birthDateTo',
          'endDateFrom',
          'endDateTo',
          'joinDateFrom',
          'joinDateTo',
        ];
        const newSearch = {
          ...searchString,
        };
        Object.keys(newSearch).forEach((e: string) => {
          if (keyDateList.indexOf(e) > -1) {
            newSearch[e] = formatDateToPostUnix(newSearch[e]);
          }
        });
        return {
          url: `/employee/employees`,
          params: { search, page, sortType, sortBy, ...newSearch },
        };
      },
      providesTags: ['EmployeeList', 'EmployeeDelete'],
    }),
    getDetailEmployee: builder.query<EmployeeProfileDataType, string>({
      query: id => `/employee/employees/${id}/profile`,
      providesTags: ['EmployeeList'],
    }),
    getEmployeeDropdown: builder.query<
      ListResponse<OptionProps>,
      QueryParamsPagination
    >({
      query: params => {
        const { search, page, limit, sortType, sort, searchString } = params;
        return {
          url: '/employee/employees/dropdown',
          params: { search, page, limit, sortType, sort, ...searchString },
        };
      },
      providesTags: ['EmployeeDropdown'],
    }),
    updateEmployee: builder.mutation({
      query: ({ id, body }) => ({
        url: `/employee/employees/${id}`,
        method: 'PUT',
        body: {
          title: body.title,
          content: body.content,
        },
      }),
      invalidatesTags: ['EmployeeList'],
    }),
    addEmployee: builder.mutation<EmployeeProps, CreateEmployeePayload>({
      query(body: any) {
        return {
          url: `/employee/employees/personal`,
          method: 'POST',
          body,
        };
      },
      invalidatesTags: ['EmployeeList'],
    }),
    getPersonalData: builder.query({
      query: args => {
        const { id, ...query } = args;
        return {
          url: `/employee/employees/${id}/personal`,
          params: query,
        };
      },
      providesTags: ['employeePersonalList', 'employeePersonalDetail'],
    }),
    getPersonalAddress: builder.query({
      query: args => {
        const { id } = args;
        return {
          url: `/employee/employees/${id}/address`,
        };
      },
    }),
    getPersonalDetailAddress: builder.query({
      query: args => {
        const { id, groupId, ...query } = args;
        return {
          url: `/employee/employees/${id}/address/${groupId}`,
          params: query,
        };
      },
    }),
    updateDetailPersonal: builder.mutation<any, any>({
      query: ({ id, body }) => ({
        url: `/employee/employees/${id}/personal`,
        method: 'PUT',
        body,
      }),
      invalidatesTags: ['EmployeeList'],
    }),
    deleteEmployee: builder.mutation({
      query: ({ id }) => ({
        url: `/employee/employees?id=${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['EmployeeDelete'],
    }),
    getListStatutory: builder.query<any, any>({
      query: args => {
        const { id, ...query } = args;
        return {
          url: `/employee/employees/${id}/statutories/idn`,
          params: query,
        };
      },
      providesTags: ['EmployeeList'],
    }),
    getDetailStatutory: builder.query<any, any>({
      query: args => {
        const { id, ...query } = args;
        return {
          url: `/employee/employees/${id}/statutories/idn`,
          params: query,
        };
      },
      providesTags: ['EmployeeList'],
    }),
    getListCostCenter: builder.query({
      query: args => {
        const { id, ...query } = args;
        return {
          url: `/employee/employees/${id}/cost-center`,
          params: query,
        };
      },
      providesTags: ['CostCenterList'],
    }),
    getDetailCostCenter: builder.query({
      query: args => {
        const { id, groupId, ...query } = args;
        return {
          url: `/employee/employees/${id}/cost-center/${groupId}`,
          params: query,
        };
      },
      providesTags: ['CostCenterList'],
    }),
    cancelUpcomingEmployeeCostCenter: builder.mutation<any, any>({
      query: ({ id, ccid }) => ({
        url: `/employee/employees/${id}/cost-center/${ccid}/cancellation`,
        method: 'DELETE',
      }),
      invalidatesTags: ['employeeCostCenter'],
    }),
    updateDetailStatutory: builder.mutation<any, any>({
      query: ({ id, body }) => ({
        url: `/employee/employees/${id}/statutories/idn`,
        method: 'PUT',
        body,
      }),
      invalidatesTags: ['EmployeeList'],
    }),
    getListExperience: builder.query<ExperienceProps[], QueryParams>({
      query: args => {
        const { id, ...query } = args;
        return {
          url: `/employee/employees/${id}/experiences`,
          params: query,
        };
      },
      providesTags: ['EmployeeList'],
    }),
    getDetailExperience: builder.query<ExperienceProps[], QueryParams>({
      query: args => {
        const { id, groupId, ...query } = args;
        return {
          url: `/employee/employees/${id}/experiences/${groupId}`,
          params: query,
        };
      },
      providesTags: ['EmployeeList'],
    }),
    getListEducation: builder.query<EducationProps[], QueryParams>({
      query: args => {
        const { id, ...query } = args;
        return {
          url: `/employee/employees/${id}/educations`,
          params: query,
        };
      },
      providesTags: ['EmployeeList'],
    }),
    getDetailEducation: builder.query<EducationProps[], QueryParams>({
      query: args => {
        const { id, groupId, ...query } = args;
        return {
          url: `/employee/employees/${id}/educations/${groupId}`,
          params: query,
        };
      },
      providesTags: ['EmployeeList'],
    }),
    getListFamily: builder.query<any, QueryParams>({
      query: args => {
        const { id, ...query } = args;
        return {
          url: `/employee/employees/${id}/families`,
          params: query,
        };
      },
      providesTags: ['EmployeeList'],
    }),
    getDetailFamily: builder.query<any, QueryParams>({
      query: args => {
        const { id, groupId, ...query } = args;
        return {
          url: `/employee/employees/${id}/families/${groupId}`,
          params: query,
        };
      },
      providesTags: ['EmployeeList'],
    }),
    getDetailHistory: builder.query<HistoryProps, QueryParams>({
      query: args => {
        const { id, ...query } = args;
        return {
          url: `/employee/employees/${id}/histories`,
          params: query,
        };
      },
      providesTags: ['EmployeeList'],
    }),
    getListFiles: builder.query({
      query: args => {
        const { id, ...query } = args;
        return {
          url: `/employee/employees/${id}/files`,
          params: query,
        };
      },
    }),
    getDetailFiles: builder.query({
      query: args => {
        const { id, groupId, ...query } = args;
        return {
          url: `/employee/employees/${id}/files/${groupId}`,
          params: query,
        };
      },
    }),
    getDetailEmployment: builder.query({
      query: args => {
        const { id, ...query } = args;
        return {
          url: `/employee/employees/${id}/employment`,
          params: query,
        };
      },
      providesTags: ['employeeEmployment'],
    }),
    getDetailWorkLocation: builder.query({
      query: args => {
        const { id, ...query } = args;
        return {
          url: `/employee/employees/${id}/work-locations`,
          params: query,
        };
      },
      providesTags: ['employeeWorkLocation'],
    }),
    getDetailInfoWorkLocation: builder.query({
      query: args => {
        const { id, ...query } = args;
        return {
          url: `/organization/work-locations/${id}/info`,
          params: query,
        };
      },
      providesTags: ['employeeWorkLocation'],
    }),
    getEmployeeBankList: builder.query({
      query: args => {
        const { id, ...query } = args;
        return {
          url: `/employee/employees/${id}/banks`,
          params: query,
        };
      },
      providesTags: ['employeeBank'],
    }),
    getEmployeeBankDetail: builder.query({
      query: args => {
        const { id, groupId, ...query } = args;
        return {
          url: `/employee/employees/${id}/banks/${groupId}`,
          params: query,
        };
      },
      providesTags: ['employeeBankDetail'],
    }),
    getEmployeeExport: builder.query({
      query: args => ({
        url: `/employee/employees/export`,
        params: { ...args },
      }),
    }),
    getotalEmployee: builder.query<any, void>({
      query: () => `/employee/employees/total`,
    }),
    getDetailDeductions: builder.query<any, any>({
      query: args => {
        const { id, ...query } = args;
        return {
          url: `/payroll/employee/${id}/deductions`,
          params: query,
        };
      },
      providesTags: ['EmployeeList'],
    }),
    createEmployeeCostCenter: builder.mutation<any, any>({
      query: ({ id, body }) => ({
        url: `/employee/employees/${id}/cost-center`,
        method: 'POST',
        body,
      }),
      invalidatesTags: ['employeeCostCenter'],
    }),
    updateEmployeeCostCenter: builder.mutation<any, any>({
      query: ({ id, ccid, body }) => ({
        url: `/employee/employees/${id}/cost-center/${ccid}`,
        method: 'PUT',
        body,
      }),
      invalidatesTags: ['employeeCostCenter'],
    }),
    deleteEmployeeCostCenter: builder.mutation<any, any>({
      query: ({ id, ccid }) => ({
        url: `/employee/employees/${id}/cost-center/${ccid}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['employeeCostCenter'],
    }),
    getDetailEmployeeCostCenter: builder.query<any, any>({
      query: args => {
        const { id, ccid, ...params } = args;
        return {
          url: `/employee/employees/${id}/cost-center/${ccid}`,
          params: {
            ...params,
          },
        };
      },
      providesTags: ['employeeCostCenter'],
    }),
    GetEmployeeCostCenterList: builder.query<any, any>({
      query: args => {
        const { id, ...query } = args;
        return {
          url: `/employee/employees/${id}/cost-center`,
          params: query,
        };
      },
      providesTags: ['employeeCostCenter'],
    }),
    getInfoCostCenter: builder.query<any, any>({
      query: args => {
        const { id } = args;
        return {
          url: `/payroll/cost-centers/${id}/info`,
        };
      },
    }),
    createEmployeeBank: builder.mutation<any, any>({
      query: ({ id, body }) => ({
        url: `/employee/employees/${id}/banks`,
        method: 'POST',
        body,
      }),
      invalidatesTags: ['employeeBank'],
    }),
    updateEmployeeBank: builder.mutation<any, any>({
      query: ({ id, employeeBankId, body }) => ({
        url: `/employee/employees/${id}/banks/${employeeBankId}`,
        method: 'PUT',
        body,
      }),
      invalidatesTags: ['employeeBankDetail'],
    }),
    deleteEmployeeBank: builder.mutation<any, any>({
      query: ({ id, groupId }) => ({
        url: `/employee/employees/${id}/banks/${groupId}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['employeeBank'],
    }),
    cancelEmployeeBank: builder.mutation<any, any>({
      query: ({ id, employeeBankId }) => ({
        url: `/employee/employees/${id}/banks/${employeeBankId}/cancellation`,
        method: 'DELETE',
      }),
      invalidatesTags: ['employeeBank', 'employeeBankDetail'],
    }),

    createEmployeeFiles: builder.mutation<any, any>({
      query: ({ id, body }) => ({
        url: `/employee/employees/${id}/files`,
        method: 'POST',
        body,
      }),
      invalidatesTags: ['employeeFiles'],
    }),
    updateEmployeeFiles: builder.mutation<any, any>({
      query: ({ id, ccid, body }) => ({
        url: `/employee/employees/${id}/files/${ccid}`,
        method: 'PUT',
        body,
      }),
      invalidatesTags: ['employeeFiles'],
    }),
    deleteEmployeeFiles: builder.mutation<any, any>({
      query: ({ id, groupId }) => ({
        url: `/employee/employees/${id}/files/${groupId}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['employeeFiles'],
    }),
    cancelChangesEmployeeFiles: builder.mutation<any, any>({
      query: ({ id, newId }) => ({
        url: `/employee/employees/${id}/files/${newId}/cancellation`,
        method: 'DELETE',
      }),
      invalidatesTags: ['employeeFiles'],
    }),
    getDetailEmployeeFiles: builder.query<any, any>({
      query: args => {
        const { id, ccid, ...params } = args;
        return {
          url: `/employee/employees/${id}/files/${ccid} `,
          params: {
            ...params,
          },
        };
      },
      providesTags: ['employeeFiles'],
    }),
    getEmployeeFilesList: builder.query<any, any>({
      query: args => {
        const { id, ...query } = args;
        return {
          url: `/employee/employees/${id}/files`,
          params: query,
        };
      },
      providesTags: ['employeeFiles'],
    }),
    getEmployeeExportTemplate: builder.query<any, any>({
      query: (params: any) => ({
        url: `/employee/employees/import/template`,
        params,
      }),
    }),
    bulkUploadEmployee: builder.mutation<any, any>({
      query: ({ body }) => ({
        url: `employee/employees/upload`,
        method: 'POST',
        body,
      }),
    }),
    getEmployeeMultipleProfiles: builder.query<any, any>({
      query: id => ({
        url: `/employee/employees/${id}/profiles`,
      }),
      providesTags: ['employeeProfiles'],
    }),
    getUserEmployee: builder.query<any, any>({
      query: args => ({
        url: `/employee/user-employee`,
      }),
    }),
    // TIME OFF EMPLOYEE
    // get record of all balance time off employee
    getTimeOffRecordEmployee: builder.query<
      Record<'pending' | 'approved' | 'rejected', number>,
      { employeeID: string }
    >({
      query: ({ employeeID }) => ({
        url: `/time-management/employee/time-off/time-off-record-employees/${employeeID}`,
      }),
    }),
    getCalendar: builder.query<any, any>({
      query: params => {
        const { id, startDate, endDate } = params;
        return {
          url: `/time-management/employees/${id}/calendar`,
          params: {
            startDate,
            endDate,
          },
        };
      },
    }),
    getCalendarLegend: builder.query<any, any>({
      query: params => {
        const { id, startDate, endDate } = params;
        return {
          url: `/time-management/employees/${id}/legends`,
          params: {
            startDate,
            endDate,
          },
        };
      },
    }),
    // get list of awaiting approval
    getAwaitingApprovalList: builder.query<
      {
        pending: number;
        approved: number;
        rejected: number;
        awaitingApproval: AwaitingApprovalList;
      },
      { employeeID: string }
    >({
      query: ({ employeeID }) => ({
        url: `/time-management/employee/time-off/${employeeID}`,
      }),
    }),
    // get list of awaiting approval
    getTimeOffBalanceList: builder.query<
      Array<{
        name: string;
        availableBalance: string;
        usedBalance: number;
        id: string;
      }>,
      { employeeID: string }
    >({
      query: ({ employeeID }) => ({
        url: `/time-management/employee/time-off/balance/${employeeID}`,
      }),
    }),
    employeeTimeoffDetails: builder.query<any, any>({
      query: params => {
        const { search, page, sortType, sortBy, limit, searchString, id } =
          params;
        return {
          url: `/time-management/employee/time-off-request/time-off-histories/${id}`,
          params: { search, page, sortType, sortBy, limit, ...searchString },
        };
      },
      providesTags: ['employeeTimeoffDetails'],
    }),
    employeeOvertimeDetails: builder.query<any, any>({
      query: params => {
        const { search, page, sortType, sortBy, limit, searchString, id } =
          params;
        return {
          url: `/time-management/employee/employee-overtime/overtime-request-details/${id}`,
          params: { search, page, sortType, sortBy, limit, ...searchString },
        };
      },
    }),
    getTotalEmployee: builder.query<any, any>({
      query: () => ({
        url: '/employee/employees/total/employee-active',
      }),
    }),
    employeeTimeOffHistory: builder.query<
      EmployeeTimeOffHistoryList,
      QueryParamsPagination
    >({
      query: ({ id, page, limit, search, searchString, sortBy, sortType }) => ({
        url: `/time-management/employee/${id}/time-off/history`,
        params: { page, limit, search, ...searchString, sortBy, sortType },
      }),
      providesTags: ['employeeTimeOffHistory'],
    }),
    employeeTimeOffEntitlement: builder.query<
      EmployeeTimeOffEntitlementList,
      QueryParamsPagination & { year: string }
    >({
      query: ({
        id,
        page,
        limit,
        search,
        searchString,
        sortBy,
        sortType,
        year,
      }) => ({
        url: `/time-management/employee/${id}/time-off/entitlement`,
        params: { page, limit, search, searchString, sortBy, sortType, year },
      }),
      providesTags: ['employeeTimeOffEntitlement'],
    }),
    empBalanceLogHistoryHeader: builder.query<
      EmpBalanceLogHistoryHeaderTypes,
      { employeeId: string; id: string; year: number }
    >({
      query: ({ employeeId, id, year }) => ({
        url: `/time-management/employee/${employeeId}/time-off/${id}/header`,
        params: { year },
      }),
    }),
    empBalanceLogHistoryList: builder.query<
      EmpBalanceLogHistoryListTypes,
      QueryParamsPagination & { year: string; employeeId: string }
    >({
      query: ({
        id,
        page,
        limit,
        search,
        searchString,
        sortBy,
        sortType,
        year,
        employeeId,
      }) => ({
        url: `/time-management/employee/${employeeId}/time-off/${id}/history`,
        params: { page, limit, search, searchString, sortBy, sortType, year },
      }),
    }),
  }),
});

export const {
  useGetEmployeeListQuery,
  useGetDetailEmployeeQuery,
  useUpdateEmployeeMutation,
  useAddEmployeeMutation,
  useDeleteEmployeeMutation,
  useGetListStatutoryQuery,
  useGetDetailStatutoryQuery,
  useGetListExperienceQuery,
  useGetDetailExperienceQuery,
  useGetListEducationQuery,
  useGetDetailEducationQuery,
  useGetListFamilyQuery,
  useGetDetailFamilyQuery,
  useGetDetailHistoryQuery,
  useGetPersonalDataQuery,
  useGetPersonalAddressQuery,
  useGetPersonalDetailAddressQuery,
  useGetDetailFilesQuery,
  useGetDetailEmploymentQuery,
  useGetDetailWorkLocationQuery,
  useGetEmployeeBankListQuery,
  useGetEmployeeBankDetailQuery,
  useUpdateDetailStatutoryMutation,
  useUpdateDetailPersonalMutation,
  useGetotalEmployeeQuery,
  useGetDetailDeductionsQuery,
  useGetListFilesQuery,
  useGetDetailCostCenterQuery,
  useGetListCostCenterQuery,
  useCreateEmployeeCostCenterMutation,
  useDeleteEmployeeCostCenterMutation,
  useGetEmployeeCostCenterListQuery,
  useGetEmployeeExportQuery,
  useGetInfoCostCenterQuery,
  useUpdateEmployeeCostCenterMutation,
  useGetDetailEmployeeCostCenterQuery,
  useCreateEmployeeBankMutation,
  useUpdateEmployeeBankMutation,
  useDeleteEmployeeBankMutation,
  useCancelEmployeeBankMutation,
  useCreateEmployeeFilesMutation,
  useDeleteEmployeeFilesMutation,
  useGetDetailEmployeeFilesQuery,
  useGetEmployeeFilesListQuery,
  useUpdateEmployeeFilesMutation,
  useCancelChangesEmployeeFilesMutation,
  useCancelUpcomingEmployeeCostCenterMutation,
  useGetEmployeeExportTemplateQuery,
  useBulkUploadEmployeeMutation,
  useGetEmployeeMultipleProfilesQuery,
  useGetUserEmployeeQuery,
  useGetEmployeeDropdownQuery,
  useGetTimeOffRecordEmployeeQuery,
  useGetAwaitingApprovalListQuery,
  useGetTimeOffBalanceListQuery,
  useGetCalendarQuery,
  useGetCalendarLegendQuery,
  useEmployeeTimeoffDetailsQuery,
  useGetTotalEmployeeQuery,
  useEmployeeOvertimeDetailsQuery,
  useEmployeeTimeOffHistoryQuery,
  useEmployeeTimeOffEntitlementQuery,
  useEmpBalanceLogHistoryHeaderQuery,
  useEmpBalanceLogHistoryListQuery,
} = employeeApiSlice;
