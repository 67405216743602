const BLOOD_TYPE = [
  {
    label: 'A',
    value: 'A',
  },
  {
    label: 'B',
    value: 'B',
  },
  {
    label: 'AB',
    value: 'AB',
  },
  {
    label: 'O',
    value: 'O',
  },
];
const GENDER = [
  {
    label: 'Male',
    value: 'MALE',
  },
  {
    label: 'Female',
    value: 'FEMALE',
  },
  // {
  //   label: 'Other',
  //   value: 'OTHER',
  // },
];

const TITLE = [
  {
    label: 'Ms.',
    value: 'MS',
  },
  {
    label: 'Mrs.',
    value: 'MRS',
  },
  {
    label: 'Miss',
    value: 'MISS',
  },
  {
    label: 'Mr.',
    value: 'MR',
  },
  {
    label: 'Mx.',
    value: 'MX',
  },
];

const RELIGION = [
  {
    label: 'Islam',
    value: 'ISLAM',
  },
  {
    label: 'Catholic',
    value: 'CATHOLIC',
  },
  {
    label: 'Protestant',
    value: 'PROTESTANT',
  },
  {
    label: 'Hinduism',
    value: 'HINDUISM',
  },
  {
    label: 'Buddhism',
    value: 'BUDDHISM',
  },
  {
    label: 'Confucianism',
    value: 'CONFUCIANISM',
  },
  {
    label: 'Taoism',
    value: 'TAOISM',
  },
  {
    label: 'Sikhism',
    value: 'SIKHISM',
  },
];

const RELATIONSHIP_TYPE = [
  {
    label: 'Parent',
    value: 'PARENT',
  },
  {
    label: 'Father',
    value: 'FATHER',
  },
  {
    label: 'Mother',
    value: 'MOTHER',
  },
  {
    label: 'Child',
    value: 'CHILD',
  },
  {
    label: 'Sibling',
    value: 'SIBLING',
  },
  {
    label: 'Cousin',
    value: 'COUSIN',
  },
  {
    label: 'Spouse',
    value: 'SPOUSE',
  },
  {
    label: 'Uncle',
    value: 'UNCLE',
  },
  {
    label: 'Aunt',
    value: 'AUNT',
  },
  {
    label: 'Parents in Law',
    value: 'PARENTS_IN_LAW',
  },
  {
    label: 'Brother in Law',
    value: 'BROTHER_IN_LAW',
  },
  {
    label: 'Sister in Law',
    value: 'SISTER_IN_LAW',
  },
];

const DISABILITY = [
  {
    label: 'Hearing Disability / Impairment',
    value: 'HEARING_DISABILITY',
  },
  {
    label: 'Visual Disability / Impairment',
    value: 'VISUAL_DISABILITY',
  },
  {
    label: 'Speech Disability / Impairment',
    value: 'SPEECH_DISABILITY',
  },
  {
    label: 'Physical Disability / Impairment',
    value: 'PHYSICAL_DISABILITY',
  },
  {
    label: 'Learning Disability',
    value: 'LEARNING_DISABILITY',
  },
  {
    label: 'Mental Disability / Impairment',
    value: 'MENTAL_DISABILITY',
  },
  {
    label: 'Multiple Disabilities / Impairments',
    value: 'MULTIPLE_DISABILITIES',
  },
  {
    label: 'Cognitive Disability / Impairment',
    value: 'COGNITIVE_DISABILITY',
  },
  {
    label: 'Developmental Disability',
    value: 'DEVELOPMENTAL_DISABILITY',
  },
  {
    label: 'Chronic Health Condition / Disability',
    value: 'CHRONIC_HEALTH_CONDITION',
  },
  {
    label: 'Neurological Disability / Impairment',
    value: 'NEUROLOGICAL_DISABILITY',
  },
  {
    label: 'Mobility Disability / Impairment',
    value: 'MOBILITY_DISABILITY',
  },
  {
    label: 'Deaf blindness',
    value: 'DEAF_BLINDNESS',
  },
  {
    label: 'Psychiatric Disability / Impairment',
    value: 'PSYCHIATRIC_DISABILITY',
  },
  {
    label: 'Acquired Brain Injury (ABI)',
    value: 'ACQUIRED_BRAIN_INJURY',
  },
  {
    label: 'Intellectual Disability / Impairment',
    value: 'INTELLECTUAL_DISABILITY',
  },
  {
    label: 'Autoimmune Disabilities',
    value: 'AUTOIMMUNE_DISABILITIES',
  },
  {
    label: 'Environmental Disabilities',
    value: 'ENVIRONMENTAL_DISABILITIES',
  },
  {
    label: 'Speech and Language Disabilities',
    value: 'SPEECH_LANGUAGE_DISABILITIES',
  },
  {
    label: 'Sensory Disabilities',
    value: 'SENSORY_DISABILITIES',
  },
  {
    label: 'Hidden Disabilities',
    value: 'HIDDEN_DISABILITIES',
  },
];

const MARITAL_STATUS = [
  {
    label: 'Single',
    value: 'SINGLE',
  },
  {
    label: 'Married',
    value: 'MARRIED',
  },
  {
    label: 'Divorce',
    value: 'DIVORCE',
  },
  {
    label: 'Widow / Widower',
    value: 'WIDOW_WIDOWER',
  },
];

const DURATION = [
  {
    label: 'Day',
    value: 'DAY',
  },
  {
    label: 'Week',
    value: 'WEEK',
  },
  {
    label: 'Month',
    value: 'MONTH',
  },
  {
    label: 'Year',
    value: 'YEAR',
  },
];

const GROUP = [
  {
    label: 'Earning',
    value: 'EARNING',
  },
  {
    label: 'Deduction',
    value: 'DEDUCTION',
  },
  {
    label: 'Benefits',
    value: 'BENEFITS',
  },
  {
    label: 'Non-Payable',
    value: 'NON_PAYABLE',
  },
  {
    label: 'Other',
    value: 'OTHER',
  },
];

const ADDRESS_TYPE = [
  { label: 'National ID Address', value: 'NATIONAL_ID_ADDRESS' },
  { label: 'Residential Address', value: 'RESIDENTIAL_ADDRESS' },
];

const TAX_CALCULATION = [
  { label: 'Gross', value: 'GROSS' },
  { label: 'Nett', value: 'NETT' },
  { label: 'Gross-up', value: 'GROSS_UP' },
];

const PAY_TYPE = [
  { label: 'Recurring', value: 'RECURRING' },
  { label: 'Non-Recurring', value: 'NON_RECURRING' },
];

const YES_NO = [
  { label: 'Yes', value: 'YES' },
  { label: 'No', value: 'NO' },
];
const ALL = [
  {
    label: '(All)',
    value: '',
  },
];

const OPERATOR = [
  {
    label: 'Add',
    value: 'ADD',
  },
  {
    label: 'Substract',
    value: 'SUBSTRACT',
  },
];

const STATUTORY_OPTIONS = [
  { label: 'BPJS Ketenagakerjaan', value: 'SOCIAL_SECURITY' },
  { label: 'BPJS Kesehatan', value: 'HEALTH_CARE' },
  { label: 'Pajak', value: 'TAX' },
];

const COUNTRY = [{ label: 'Indonesia', value: 'INDONESIA' }];

const PTKP = [
  { label: 'TK/0', value: 'TK/0' },
  { label: 'TK/1', value: 'TK/1' },
  { label: 'TK/2', value: 'TK/2' },
  { label: 'TK/3', value: 'TK/3' },
  { label: 'K/0', value: 'K/0' },
  { label: 'K/1', value: 'K/1' },
  { label: 'K/2', value: 'K/2' },
  { label: 'K/3', value: 'K/3' },
  // { label: 'K/I/0', value: 'K/I/0' },
  // { label: 'K/I/1', value: 'K/I/1' },
  // { label: 'K/I/2', value: 'K/I/2' },
  // { label: 'K/I/3', value: 'K/I/3' },
];

const AMOUNT_TYPE = [
  { label: 'Fixed', value: 'FIXED' },
  { label: 'Formula', value: 'FORMULA' },
];

const PAY_FREQUENCY = [
  {
    label: 'Daily',
    value: 'DAILY',
  },
  {
    label: 'Weekly',
    value: 'WEEKLY',
  },
  {
    label: 'Monthly',
    value: 'MONTHLY',
  },
];

const BPJS_KETENAGAKERJAAN_PROGRAM = [
  { label: 'Jaminan Hari Tua (JHT)', value: 'OLD_AGE_SECURITY' },
  {
    label: 'Jaminan Kecelakaan Kerja (JKK)',
    value: 'EMPLOYMENT_INJURY_SECURITY',
  },
  { label: 'Jaminan Pensiun (JP)', value: 'PENSION_SECURITY' },
  { label: 'Jaminan Kematian (JKM)', value: 'DEATH_SECURITY' },
];

const RISK_LEVEL = [
  { label: 'Very Low (0,24%)', value: 'VERY_LOW' },
  { label: 'Low (0,54%)', value: 'LOW' },
  { label: 'Medium (0,89%)', value: 'MEDIUM' },
  { label: 'High (1,27%)', value: 'HIGH' },
  { label: 'Very High (1,74%)', value: 'VERY_HIGH' },
];

const INACTIVITY_CRITERIA = [
  {
    label: 'When any person is inactive',
    value: 'WHEN_ANY_PERSON_IS_INACTIVE',
  },
  {
    label: 'When all people are inactive',
    value: 'WHEN_ALL_PEOPLE_ARE_INACTIVE',
  },
];
const MOVE_TYPE = [
  { label: 'Only this period', value: 'ONLY_THIS_PERIOD' },
  { label: 'Permanent', value: 'PERMANENT' },
];

const PAYMENT_TYPE = [
  { label: 'Bank Transfer', value: 'BANK_TRANSFER' },
  { label: 'Cheque', value: 'CHEQUE', disabled: true },
  { label: 'Cash', value: 'CASH', disabled: true },
];

const ADJUSTMENT_TYPE = [
  { label: 'Addition', value: 'ADDITION' },
  { label: 'Deduction', value: 'DEDUCTION' },
];

const OPTIONS_DYNAMIC_GROUP = {
  basedOn: [
    { label: 'Legal Entity', value: 'LEGAL_ENTITY' },
    { label: 'Organization', value: 'ORGANIZATION' },
    { label: 'Job Grade', value: 'JOB_GRADE' },
    { label: 'Job Position', value: 'JOB_POSITION' },
    { label: 'Work Location', value: 'WORK_LOCATION' },
  ],
};

const ACCOUNT_TYPE = [
  { label: 'Debit', value: 'DEBIT' },
  { label: 'Credit', value: 'CREDIT' },
];

const EMPLOYMENT_STATUS = [
  { label: 'Active', value: 'ACTIVE' },
  { label: 'Inactive', value: 'INACTIVE' },
  { label: 'Pending', value: 'PENDING' },
  { label: 'Terminating', value: 'TERMINATING' },
];

const FILE_TYPE = [
  { label: 'Excel (.xlsx)', value: 'XLSX' },
  { label: 'Excel 97-2004 (.xls)', value: 'XLS' },
  // { label: 'OpenDocument Spreadsheet (.ods)', value: 'ODS' },
];

const ACTIVE_TRANSITION_TYPE: Array<{ label: string; value: string }> = [
  { label: 'Contract Extension', value: 'CONTRACT_EXTENSION' },
  { label: 'Promotion', value: 'PROMOTION' },
  { label: 'Termination', value: 'TERMINATION' },
];

const INACTIVE_TRANSITION_TYPE: Array<{ label: string; value: string }> = [
  { label: 'Rehirement', value: 'REHIREMENT' },
];

const EMP_DATA_ADJUSTMENT: Array<{ label: string; value: string } | null> = [
  { label: 'Employment Info', value: 'EMPLOYMENT_INFO' },
  { label: 'Work Location Info', value: 'WORK_LOCATION' },
  { label: 'Earnings Info', value: 'EARNINGS_INFO' },
  { label: 'Deductions Info', value: 'DEDUCTIONS_INFO' },
];
const EMP_DATA_ADJUSTMENT_TAX_LOCATION: Array<{
  label: string;
  value: string;
} | null> = [
  { label: 'Employment Info', value: 'EMPLOYMENT_INFO' },
  { label: 'Work Location Info', value: 'WORK_LOCATION' },
  { label: 'Statutory Info — Tax', value: 'TAX_LOCATION_INFO' },
  { label: 'Earnings Info', value: 'EARNINGS_INFO' },
  { label: 'Deductions Info', value: 'DEDUCTIONS_INFO' },
];
const YEAR = [
  {
    label: '2023',
    value: '2023',
  },
  {
    label: '2024',
    value: '2024',
  },
  {
    label: '2025',
    value: '2025',
  },
  {
    label: '2026',
    value: '2026',
  },
];
const MONTH = [
  {
    label: 'Jan',
    value: '1',
  },
  {
    label: 'Feb',
    value: '2',
  },
  {
    label: 'Mar',
    value: '3',
  },
  {
    label: 'Apr',
    value: '4',
  },
  {
    label: 'May',
    value: '5',
  },
  {
    label: 'Jun',
    value: '6',
  },
  {
    label: 'Jul',
    value: '7',
  },
  {
    label: 'Aug',
    value: '8',
  },
  {
    label: 'Sep',
    value: '9',
  },
  {
    label: 'Oct',
    value: '10',
  },
  {
    label: 'Nov',
    value: '11',
  },
  {
    label: 'Dec',
    value: '12',
  },
];

const OPTIONS = {
  STATUTORY_OPTIONS,
  BLOOD_TYPE,
  GENDER,
  RELIGION,
  RELATIONSHIP_TYPE,
  DISABILITY,
  MARITAL_STATUS,
  DURATION,
  GROUP,
  ADDRESS_TYPE,
  TAX_CALCULATION,
  PAY_TYPE,
  YES_NO,
  COUNTRY,
  PTKP,
  ALL,
  BPJS_KETENAGAKERJAAN_PROGRAM,
  RISK_LEVEL,
  OPERATOR,
  AMOUNT_TYPE,
  PAY_FREQUENCY,
  INACTIVITY_CRITERIA,
  MOVE_TYPE,
  PAYMENT_TYPE,
  ADJUSTMENT_TYPE,
  OPTIONS_DYNAMIC_GROUP,
  ACCOUNT_TYPE,
  EMPLOYMENT_STATUS,
  FILE_TYPE,
  ACTIVE_TRANSITION_TYPE,
  INACTIVE_TRANSITION_TYPE,
  EMP_DATA_ADJUSTMENT,
  EMP_DATA_ADJUSTMENT_TAX_LOCATION,
  TITLE,
  YEAR,
  MONTH,
};

export default OPTIONS;
