import { createSlice } from '@reduxjs/toolkit';
import { claimProcessApiSlice } from './service/claim/claimProcessApiSlice';

interface initialStateI {
  messageSuccess: string;
  messageError: string;
}

const initialState: initialStateI = {
  messageSuccess: '',
  messageError: '',
};

const claimProcessSlice = createSlice({
  name: 'claimProcess',
  initialState,
  reducers: {
    removeMessage: state => {
      state.messageSuccess = '';
      state.messageError = '';
    },
  },
  extraReducers(builder) {
    builder.addMatcher(
      claimProcessApiSlice.endpoints.createClaimProcess.matchFulfilled,
      (state, _action) => {
        state.messageSuccess = 'Claim Process created.';
      }
    );
    builder.addMatcher(
      claimProcessApiSlice.endpoints.createClaimProcess.matchRejected,
      (state, action) => {
        const payload = action.payload as any;
        state.messageError = payload?.data?.message || 'Internal Server Error.';
      }
    );
    builder.addMatcher(
      claimProcessApiSlice.endpoints.deleteClaimProcess.matchFulfilled,
      (state, _action) => {
        state.messageSuccess = 'Claim Process deleted.';
      }
    );
    builder.addMatcher(
      claimProcessApiSlice.endpoints.deleteClaimProcess.matchRejected,
      (state, action) => {
        const payload = action.payload as any;
        state.messageError = payload?.data?.message || 'Internal Server Error.';
      }
    );
    builder.addMatcher(
      claimProcessApiSlice.endpoints.reprocessClaim.matchRejected,
      (state, action) => {
        console.log(state, action);
        state.messageError =
          'This data can’t be reprocessed as it had been processed in another module.';
      }
    );
    builder.addMatcher(
      claimProcessApiSlice.endpoints.claimReprocessingStatus.matchFulfilled,
      (state, action) => {
        if (action.payload?.statusCode === 'COMPLETED') {
          state.messageSuccess = 'Attendance reprocessed';
        }
      }
    );
  },
});

export const { removeMessage } = claimProcessSlice.actions;
export const claimProcessReducer = claimProcessSlice.reducer;
